import React from 'react';
import { Link, Outlet, useParams } from "react-router-dom";

const Consult = () => {
  return (
    <main className='h-full flex flex-col gap-2'>
      <div className='flex gap-4 p-2'>
        <Link className='text-sm capitalize font-bold' to="/dashboard/consultation">Consultation</Link>
        <Link className='text-sm capitalize font-bold' to="/dashboard/consultation/prescription">Prescription</Link>
      </div>
      <div className='h-[80vh]  rounded-lg p-4'>
        <Outlet />
      </div>
    </main>
  )
}

export default Consult
