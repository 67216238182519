import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { baseUrl } from '../../../../utils/constant';
import Alert from '../../../../components/Alert';

const Prescrib = () => {
    const { data } = useSelector((state) => state.user);


    const [formData, setFormData] = useState({
        staff: `${data?.firstname} ${data?.lastname}`,
        ref: "",
        presc: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const save = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${baseUrl}/prescriptions.php`, formData);
            if (res.status === 201) {
                Alert("success", "PRESCRIPTION SENT");
            } else {
                console.log(res);
                Alert("error", "Failed to send prescription");
            }
        } catch (error) {
            console.error("Error sending prescription:", error);
            Alert("error", "An error occurred while sending the prescription");
        }
    };

    return (
        <main >
            <form action="" className='flex flex-col gap-4' >
                <div className='grid grid-cols-3 gap-4'>
                    <div className="w-full">
                        <input
                            type="text"
                            name="ref"
                            id="ref"
                            className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                            placeholder="Reference Number"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <textarea name="presc" onChange={handleChange} placeholder='PRESCRIPTION' className='p-4 rounded-lg' rows={10} id="presc"></textarea>

                <button onClick={save} className='bg-appColor rounded-md w-fit px-5 py-2 font-bold text-white'>SEND PRESCRIPTION</button>
            </form>

        </main>
    )
}

export default Prescrib
